<template>
  <!--
    OFFERINGS
  -->
  <section id="offerings" class="py-14 bg-cultured">

    <div class="container">
      <h2 class="text-3xl leading-tight mb-6 font-heading iods text-center">Here are my offerings:</h2>
      <div class="flex flex-wrap justify-center md:justify-start lg:px-24">

        <div class="w-3/4 sm:w-2/3 md:w-1/2 lg:w-1/3">
          <div class="pricing-box bg-cultured-light border-t-8 border-gate-lighter">
            <div class="mb-3">
              <h3 class="package-name">Basic</h3>
            </div>
            <div class="mb-5">
              <p class="text-gray-700">
                <span class="font-bold text-2xl">$45.00</span>
                <span class="font-medium text-sm"> / starting hourly</span>
              </p>
            </div>
            <ul class="mb-12">
              <li class="text-gray-500 leading-9">Development</li>
              <li class="text-gray-500 leading-9">Project Audit</li>
            </ul>
            <a href="#" class="btn">Quote Me!</a>
          </div>
        </div>

        <div class="w-3/4 sm:w-2/3 md:w-1/2 lg:w-1/3">
          <div class="pricing-box bg-cultured-light border-t-8 border-gate-light">
            <div class="mb-3">
              <h3 class="package-name">Standard</h3>
            </div>
            <div class="mb-5">
              <p class="text-gray-700">
                <span class="font-bold text-2xl">$60.00</span>
                <span class="font-medium text-sm"> / starting hourly</span>
              </p>
            </div>
            <ul class="mb-12">
              <li class="text-gray-500 leading-9">Cloud Services</li>
              <li class="text-gray-500 leading-9">Development</li>
              <li class="text-gray-500 leading-9">Project Audit</li>
            </ul>
            <a href="#" class="btn">Quote Me!</a>
          </div>
        </div>

        <div class="w-3/4 sm:w-2/3 md:w-1/2 lg:w-1/3">
          <div class="pricing-box bg-cultured-light border-t-8 border-gate">
            <div class="mb-3">
              <h3 class="package-name">Premium</h3>
            </div>
            <div class="mb-5">
              <p class="text-gray-700">
                <span class="font-bold text-2xl">$85.00</span>
                <span class="font-medium text-sm"> / starting hourly</span>
              </p>
            </div>
            <ul class="mb-12">
              <li class="text-gray-500 leading-9">Cloud Services</li>
              <li class="text-gray-500 leading-9">Development</li>
              <li class="text-gray-500 leading-9">Project Audit</li>
              <li class="text-gray-500 leading-9">Jira Support</li>
            </ul>
            <a href="#" class="btn">Quote Me!</a>
          </div>
        </div>

      </div>
    </div>

  </section>
  <!--
    OFFERINGS END
  -->
</template>