<template>
  <!--
    COLLABORATORS
  -->
  <div class="bg-white-100">

    <section class="container">
      <div class="px-12 pt-20 mb-20 text-center">
        <div class="max-w-5xl mx-auto mb-12">
          <h2 class="text-3xl leading-tight mb-6 font-heading iods">I am proud to have worked with some fantastic companies:</h2>
        </div>
        <div class="flex flex-wrap -mx-8 items-center">
          <div class="w-1/2 md:w-1/6 px-8 mb-8 opacity-50 hover:opacity-100"><img src="../assets/img/hay_house.png" alt="Hay House Publishing"></div>
          <div class="w-1/2 md:w-1/6 px-8 mb-8 opacity-50 hover:opacity-100"><img src="../assets/img/l7_1.png" alt="L7 Creative"></div>
          <div class="w-1/2 md:w-1/6 px-8 mb-8 opacity-50 hover:opacity-100"><img src="../assets/img/optelec_2.png" alt="Optelec"></div>
          <div class="w-1/2 md:w-1/6 px-8 mb-8 opacity-50 hover:opacity-100"><img src="../assets/img/burton_2.png" alt="Burton Snowboards"></div>
          <div class="w-1/2 md:w-1/6 px-8 mb-8 opacity-50 hover:opacity-100"><img src="../assets/img/prana_2.png" alt="Prana Clothing"></div>
          <div class="w-1/2 md:w-1/6 px-8 mb-8 opacity-50 hover:opacity-100"><img src="../assets/img/fruition.png" alt="Fruition"></div>
        </div>
      </div>
    </section>

  </div>
  <!--
    COLLABORATORS END
  -->
</template>