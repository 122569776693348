<template>
  <!--
    PROFILE
  -->
  <section class="container" id="profile">

    <div class="py-12 lg:px-12 md:px-6">
      <div class="mx-auto">
        <div class="flex flex-wrap px-8">
          <div class="w-full lg:w-1/3 lg:px-4 md:px-2 py-6">
            <img src="../assets/img/code_1.svg" alt="" class="h-32 rounded-lg w-full hover:opacity-50">
            <div class="px-6 py-4 border-b">
              <div class="text-xl text-center iods text-gate">Software Development</div>
            </div>
            <p class="text-base text-center text-gray-800 pt-4">I specialize in applications written in PHP; focusing in Magento. Recently, I have become a HUGE fan of Go and building CLI tools.</p>
            <div class="text-center">
              <div class="iods pt-4 text-gate">Languages I Speak:</div>
              <p class="py-2 text-gray-800">PHP, JavaScript, Go, Python, Bash, SQL</p>
              <div class="iods pt-3 text-gate">Some tools of mine:</div>
              <ul class="py-2">
                <li>PHPStorm, Webstorm, DataGrip</li>
                <li>PyCharm, GoLand</li>
                <li>ohmyzsh, iTerm</li>
                <li>Postman</li>
                <li>repl.it</li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/3 lg:px-4 md:px-2 py-6">
            <img src="../assets/img/code_3.svg" alt="" class="h-32 w-full hover:opacity-50">
            <div class="px-6 py-4 border-b">
              <div class="text-xl text-center iods text-gate">Cloud Architecture</div>
            </div>
            <p class="text-base text-center text-gray-800 pt-4">I find value in cost-effective, high-performing, resilient, and secure architectures. Oh and by the way, I like to build them too.</p>
            <div class="text-center">
              <div class="iods pt-4 text-gate">Solutions I Architect:</div>
              <p class="py-2">Application Stacks, Pipelines, Cloud Monitoring</p>
              <div class="iods pt-3 text-gate">Some focuses of mine:</div>
              <ul class="py-2">
                <li>CI/CD (Bitbucket, Travis, Actions)</li>
                <li>Google Cloud Platform</li>
                <li>Rancher, Traefik</li>
                <li>Pantheon</li>
                <li>Azure</li>
              </ul>
            </div>
          </div>
          <div class="w-full lg:w-1/3 lg:px-4 md:px-2 py-6">
            <img src="../assets/img/evangelism.svg" alt="" class="h-32 rounded-lg w-full hover:opacity-25">
            <div class="px-6 py-4 border-b">
              <div class="text-xl text-center iods text-gate">Developer Growth</div>
            </div>
            <p class="text-base text-center text-gray-800 pt-4">We are never done growing. Through the years I have had some wonderful mentors and am very receptive to feedback and help.</p>
            <div class="text-center">
              <div class="iods pt-4 text-gate">Philosophies I Support:</div>
              <p class="py-2 text-gray-800">Mentorship, Community, Evangelism</p>
              <div class="iods pt-3 text-gate">Some practices I follow:</div>
              <ul class="py-2">
                <li>Commit early; commit often</li>
                <li>Pair programming</li>
                <li>Code reviews</li>
                <li>Open Source</li>
                <li>Mentorship</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>
  <!--
    PROFILE END
  -->
</template>

